import React, { useEffect,useState } from "react";
import {
  ActivityIndicator,
  Image,
  Modal,
  Text as NativeText,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import Layout from "../../../components/Layout/Layout";
import Text from "../../../components/Text";
import SelectPatient from "../../../components/Woopi/SelectPatient";
import Theme from "../../../style/Theme";

import InputSpinner from "react-native-input-spinner";
import TextInput from "../../../components/TextInput";
import Colors from "../../../configs/Colors";

import AccountItem from "../../../components/AccountItem/Index";
import FullScreenGreenCheck from "../../../components/FullscreenGreenCheck";
import FullscreenProgress from "../../../components/FullscreenProgress";
import FullScreenLoading from "../../../components/fullscreenLoading";
import locales_es from "../../../locales/es";

import ButtonBorder from "../../../components/Buttons/ButtonBorder";
import ButtonText from "../../../components/Buttons/ButtonText";
import Card from "../../../components/Card";
import DiagnosticAutocompleteItem from "../../../components/DiagnosticAutocomplete";
import Overlay from "../../../components/Overlay";
import PatientMedicalInsurancesList from "../../../components/PatientMedicalInsurances";
import PrescriptionProfiles from "../../../components/WorkProfileManagement/PrescriptionProfiles";
import FormNewDatePicker from "../../../components/formNewDatePicker";
import { EVENT_SHOW_MODAL_INFO } from "../../../constants/Events";
import { SOURCE_ICON } from "../../../images";
import avatarProfileIcon from "../../../images/avatar/ic_account_normal1.png";
import iconHelpWhite from "../../../images/ic_help_white@3x.png";
import iconWarning from "../../../images/img_warning.png";
import { EventRegister } from "../../../modules/EventRegister";
import { MyPatientsDesktop, MyPatientsMobile } from "../../MyPatients";
import SearchMedicines from "../../SearchMedicines";
import useAddPrescriptionPage from "../shared/useAddPrescriptionPage";

interface AddPrescriptionProps {}

const AddPrescription = (props: AddPrescriptionProps) => {
  const [tieneCobertura, setTieneCobertura] = useState(null);
  const [noReplacementActivated, setNoReplacementActivated] = useState(false);
  const {
    overlay,
    congrats,
    loadingText,
    loading,
    userData,
    errors,
    dates,
    minDate,
    diagnostic,
    diagnosticCod,
    indications,
    extendedTreatment,
    hiv,
    removeDate,
    removeMedicine,
    patientId,
    theme,
    medicines,
    setMedicines,
    prescriptionProfileId,
    prescriptionProfiles,
    prescriptionRequestId,
    progress,
    prescriptionError,
    onSend,
    onSwitchPressIconNeedsDuplicate,
    onBlur,
    onPrescriptionProfileSelect,
    onSwitchPressNeedsDuplicate,
    onSwitchPressNoReplacement,
    onSwitchPressOnlyGeneric,
    correctPatientNoInsurance,
    sendWithNoInsurance,
    addNewDate,
    setDateArray,
    setDiagnostic,
    setExtendedTreatment,
    setHiv,
    setIndications,
    setMedicineQuantity,
    setOverlay,
    setPatientId,
    setPrescriptionError,
    setUserData,
    loadMedicine,
    onPressSearchResult,
    onSetObservation,
    setMedicineDiagnostic,
    medicalInsurances,
    onMedicalInsuranceSelect,
    scrollToTop,
    comments,
    setComments,
    medicalInsuranceIdSelected,
    permissions,
    overflowFlag,
    setOverflowFlag,
    insurancePermissionFlag,
    selectPrescriptionProfileEnabled
  } = useAddPrescriptionPage(props);

  const goToSearchMedicines = () => {
      setOverflowFlag(false);
    setOverlay(
      <>
        <SearchMedicines
          showMainModal={(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          ) =>
            props.showModal(
              title,
              content,
              actions,
              customStyles,
              contentCustomClassName
            )
          }
          hideMainLoading={props.hideMainLoading}
          onSelectItem={(selectedItem) => {
            setOverlay(null);
            loadMedicine(selectedItem);
          }}
          onCancel={() => {
            setOverlay(null);
          }}
        />
      </>
    );
  };

  const setPatient = (patient) =>{
     localStorage.setItem("patientSelected",JSON.stringify(patient));
  }

  const renderItem = (item, index) => {
    const tc = localStorage.getItem('tieneCobertura') === "1" ?1:tieneCobertura?tieneCobertura:0;

    return (
      <div key={"medicine-item-" + index + item.id}>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <View
              key={index.toString()}
              style={{
                paddingLeft: 24,
                marginVertical: 10,
                zIndex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text size={16} lineHeight={24} medium>
                <NativeText
                  style={[
                    item.is_generic ? styles.lineThrough : {},
                    item.no_replacement ? styles.underline : {},
                  ]}
                >
                  {item.commercial_name}
                </NativeText>{" "}
                ({item.drug}) {item.presentation}
              </Text>
              <View>
                <TouchableOpacity
                  key={index.toString() + 1}
                  onPress={() => {
                    removeMedicine(index);
                  }}
                  style={{
                    zIndex: 2,
                    marginRight: 16,
                    paddingVertical: 10,
                  }}
                >
                  <Image
                    source={SOURCE_ICON.trashIcon}
                    style={{
                      tintColor: "red",
                      width: 20,
                      height: 20,
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>


            {tc !== 1 && (
              <AccountItem
                style={styles.switch}
                name={locales_es.prescriptOnlyGeneric}
                isToggle={true}
                onPress={() => onSwitchPressOnlyGeneric(index)}
                switchValue={item.is_generic}
              />
            )}


            <AccountItem
              style={styles.switch}
              name={locales_es.noReplacement}
              isToggle={true}
              onPress={() => {
                onSwitchPressNoReplacement(index);
              }}
              switchValue={Boolean(item.no_replacement)} />


            <AccountItem
              icon={item.need_duplicate_reason ? iconHelpWhite : null}
              linearColorDisabled={true}
              iconInTitle={true}
              iconStyle={styles.switchIconStyle}
              style={styles.switch}
              nameStyle={
                item.need_duplicate_reason
                  ? { minWidth: 120, marginLeft: 0 }
                  : null
              }
              name={locales_es.needsDuplicate}
              isToggle={true}
              onPress={() => onSwitchPressNeedsDuplicate(index)}
              onPressIcon={() => onSwitchPressIconNeedsDuplicate(index)}
              switchValue={item.is_duplicated}
            />
            <Text
              size={16}
              lineHeight={24}
              bold
              style={{
                marginLeft: 24,
              }}
            >
              {locales_es.diagnosis}
            </Text>
            <DiagnosticAutocompleteItem
              diagnosticIn={item.diagnostic}
              diagnosticCodIn={item.diagnostic_cod}
              errorsIn={errors}
              tagIn={`medicine-diagnostic-${index}-`}
              paddingRightFlagIn={false}
              onPress={(item) => onPressSearchResult(item, index)}
              onChange={(text) => setMedicineDiagnostic(index, text)}
            />
            <Text
              size={16}
              lineHeight={24}
              bold
              style={{
                marginLeft: 24,
                marginBottom: 10,
              }}
            >
              {locales_es.observations}
            </Text>
            <View style={{
              height: 100,
              width: '87%',
              alignSelf: 'center',
              }}>
                <TextInput
                  placeholderTextColor={theme.placeholder}
                  value={item.observations}
                  placeholder={"Añada aquí las observaciones para el medicamento"}
                  style={{
                    fontFamily: "Muli_400Regular",
                    fontSize: 15,
                    lineHeight: 18,
                    color: theme.text,
                    flex: 1,
                    borderWidth: 1,
                    borderColor: theme.innearColor,
                  }}
                  onChangeText={(e)=>{
                    onSetObservation(index,e)
                  }}
                  multiline={true}
                  editable={true}
                  maxLength={100}
                />
            </View>
            <View
              style={{
                alignSelf: "center",
                marginTop: 10,
                marginBottom: 20,
                paddingLeft: 40,
                transform: [{ scale: 0.75 }],
              }}
            >
              <InputSpinner
                editable={false}
                max={99}
                min={1}
                step={1}
                colorMax={Colors.Tomato}
                colorMin={Colors.TealBlue}
                color={Colors.TealBlue}
                value={Number(item.quantity)}
                skin="modern"
                onChange={(num) => setMedicineQuantity(index, num)}
              />
            </View>
          </View>
        </View>
      </div>
    );
  };

  const selectPatient = () => {
    setOverflowFlag(true);
    setOverlay(
      <View style={{ position: "relative", paddingTop: 24 }}>
        <MyPatientsDesktop
          showMainModal={(
            title,
            content,
            actions,
            customStyles,
            contentCustomClassName
          ) =>
            props.showModal(
              title,
              content,
              actions,
              customStyles,
              contentCustomClassName
            )
          }
          hideMainLoading={props.hideMainLoading}
          selectPatientId={true}
          onSelectPatient={(patient) => {
            setPatientId(patient.id);
            setUserData(patient);
            setOverlay(null);
            setPatient(patient);
          }}
          source="prescription"
          onGoBack={() => setOverlay(null)}
        />
      </View>
    );
  };

  const handleScrollToOverlay = () => {
    const overlayElement = document.getElementById('idOverlayPrescription');
    if (overlayElement) {
      overlayElement.scrollIntoView({ behavior: "smooth" });
    }
  };

   useEffect(() => {
    handleScrollToOverlay()

    if(userData){
      setPatient(userData);
    }


    getDefaultCheckInsurance();

  },[overlay,medicines])

  const getDefaultCheckInsurance = () => {

  let patient:any = userData;

  if(patient){
        for (let i = 0; i < patient.patients_medical_insurances.length; i++) {
          const p = patient.patients_medical_insurances[i];
          if(p.medical_insurance_selected == 1){

            let idFinanciador = p.medical_insurance.idFinanciador?p.medical_insurance.idFinanciador:0;
            let tieneCobertura = p.medical_insurance.tieneCobertura?p.medical_insurance.tieneCobertura:null;

              //Vademecun Especial
            if(idFinanciador > 0 && tieneCobertura == 1){
              setTieneCobertura(1);
              localStorage.setItem("tieneCobertura",'1');
            }
            break;
          }


      }
    }

  };

  const onInsuranceChange = (selectedInsuranceId) => {
    if (selectedInsuranceId === null) {
      setMedicines([]);
    }
  };

  const onTieneCoberturaVoid = (t:any) => {
    setTieneCobertura(t);
  };

  const onEnableNoReplacement = (oldInsu:any,newInsu:any) => {

    if(oldInsu.tieneCobertura === 1 && newInsu.tieneCobertura === 0){
      const newMedicines: any[] = medicines.map((medicine: any) => {
        return { ...medicine, no_replacement: false}
      });
      setMedicines(newMedicines);
    }
  };

  return (
    <>
      {overlay && (
        <Overlay
          id="idOverlayPrescription"
          style={{ left: 0, top: 0, height: "100%", width: "100%" }}
        >
          {overlay}
        </Overlay>
      )}
      {progress ? (
        <FullscreenProgress progress={progress} text={loadingText} />
      ) : null}
      {congrats && (
        <FullScreenGreenCheck
          text={locales_es.createdPrescriptionSuccessfully}
        />
      )}
      {loading && <FullScreenLoading text={loadingText} />}
      {!overlay && (
         <View style={{...styles.container, overflow: (overlay) ? 'hidden' : 'auto'}}>
          <View style={styles.content}>
            <View style={styles.headerWrapper}>
              <Text size={38} lineHeight={36} bold>
                Crear receta
              </Text>
            </View>
            <View style={styles.user}>
              <TouchableOpacity onPress={selectPatient}>
                {patientId ? (
                  <>
                    {userData && (
                      <View style={styles.userDataWrapper}>
                        <View style={styles.userName}>
                          <View>
                            <Image
                              source={
                                userData.full_profile_image
                                  ? { uri: userData.full_profile_image }
                                  : avatarProfileIcon
                              }
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: 25,
                              }}
                            />
                          </View>
                          <View>
                            <Text
                              size={20}
                              lineHeight={24}
                              semibold
                              marginRight={32}
                            >
                              {userData.name} {userData.lastname}
                            </Text>
                            {userData.gender && userData.gender.name && (
                              <Text
                                size={18}
                                lineHeight={22}
                                bold
                                marginRight={32}
                              >
                                {userData.gender.name}
                              </Text>
                            )}
                          </View>
                        </View>
                        <ButtonLinear
                          white
                          styleButton={{ width: 200 }}
                          title={
                            dates && dates.length && dates.length > 1
                              ? locales_es.createPrescriptions
                              : locales_es.createPrescription
                          }
                          onPress={onSend}
                          disabled={!medicines?.length}
                        />
                      </View>
                    )}
                  </>
                ) : (
                  <SelectPatient
                    style={{
                      borderWidth: 1,
                      borderColor: errors.includes("patientId")
                        ? theme.lineErrorColor
                        : theme.innearColor,
                    }}
                  />
                )}
              </TouchableOpacity>
            </View>

            <View style={styles.cardWrapper}>
              <View style={styles.cardItem}>
                <Card
                  header={{
                    icon: SOURCE_ICON.exp,
                    title:
                      locales_es.configuration_page.prescription_profiles_info,
                    action: (
                      <TouchableOpacity
                        onPress={() => {
                          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                            title: locales_es.prescriptionProfilesInfo.title,
                            message:
                              locales_es.prescriptionProfilesInfo.content,
                          });
                        }}
                      >
                        <Image
                          source={{
                            uri: SOURCE_ICON.help,
                            width: 25,
                            height: 25,
                          }}
                          style={styles.cardAction}
                        />
                      </TouchableOpacity>
                    ),
                  }}
                >
                  {typeof prescriptionProfiles === "undefined" && (
                    <ActivityIndicator />
                  )}
                  {prescriptionProfiles === null && (
                    <Text size={16}>
                      {locales_es.configuration_page.no_prescription_profile}
                    </Text>
                  )}
                  {prescriptionProfiles && (
                    <View style={styles.prescriptionProfileWrapper}>
                      <PrescriptionProfiles
                        {...userData}
                        itemStyle={{ borderRadius: 0 }}
                        removeTitle
                        prescriptionProfiles={prescriptionProfiles}
                        onProfileSelect={onPrescriptionProfileSelect}
                        selectedProfileId={prescriptionProfileId}
                        style={
                          errors.includes("prescriptionProfileId")
                            ? styles.sectionError
                            : null
                        }
                        editable={!prescriptionRequestId}
                        // editable={false}
                      />
                    </View>
                  )}
                </Card>

                {userData && insurancePermissionFlag && (
                  <View>
                    <PatientMedicalInsurancesList
                      patientMedicalInsurances={medicalInsurances}
                      onMedicalInsuranceSelect={onMedicalInsuranceSelect}
                      patientInsuranceSelected={medicalInsuranceIdSelected}
                      permissions={permissions}
                      edit={false}
                      onTieneCoberturaVoid={onTieneCoberturaVoid}
                      onInsuranceChange={onInsuranceChange}
                      onEnableNoReplacement={onEnableNoReplacement}
                    />
                  </View>
                )}

                <Card
                  header={{
                    title:
                      dates.length > 1
                        ? locales_es.prescriptionsDates
                        : locales_es.prescriptionDate,
                    icon: SOURCE_ICON.calendar,
                    action: (
                      <TouchableOpacity
                        onPress={() => {
                          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                            title: locales_es.prescriptionDatesInfo.title,
                            message: locales_es.prescriptionDatesInfo.content,
                          });
                        }}
                      >
                        <Image
                          source={{
                            uri: SOURCE_ICON.help,
                            width: 25,
                            height: 25,
                          }}
                          style={styles.cardAction}
                        />
                      </TouchableOpacity>
                    ),
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    {dates && dates.length
                    ? dates.map((_date: any, index: number) => {
                        return (
                          <View key={"datePicker-" + index}>
                            <FormNewDatePicker
                              withPortal={true}
                              portalId={"datePicker-" + index}
                              value={_date}
                              placeholder={locales_es.date}
                              // required={true}
                              handleChange={(date: any) => {
                                setDateArray(date, index);
                              }}
                              minDate={minDate}
                              excludeDates={dates}
                            />
                          </View>
                        );
                      })
                    : null}

                    {dates && dates.length && dates.length > 1 ? (
                      <ButtonText
                        marginTop={16}
                        title={locales_es.removeDate}
                        style={styles.buttonDate}
                        onPress={removeDate}
                        titleColor={Colors.Red}
                      />
                    ) : null}
                    {dates &&
                    dates.length ? (
                      <ButtonText
                        marginTop={20}
                        title={locales_es.addDate}
                        style={styles.buttonDate}
                        onPress={addNewDate}
                      />
                    ) : null}
                  </View>
                </Card>

                <Card
                  header={{
                    title: locales_es.medicines,
                    icon: SOURCE_ICON.medication,
                    action: medicines.length < 3 && patientId && (
                      <TouchableOpacity
                        onPress={goToSearchMedicines}
                        style={styles.prescriptionAction}
                      >
                        <ButtonLinear
                          white
                          style={styles.prescriptionButton}
                          title=""
                          onPress={goToSearchMedicines}
                        >
                          <Image
                            source={SOURCE_ICON.plus}
                            style={styles.prescriptionIcon}
                          />
                        </ButtonLinear>
                      </TouchableOpacity>
                    ),
                  }}
                >
                  <>
                    {medicines && medicines.length ? (
                      <>
                        <Layout
                          style={{
                            marginTop: 24,
                            paddingTop: 16,
                            borderRadius: 16,
                            boxShadow: "none",
                          }}
                        >
                          {medicines.map(renderItem)}
                        </Layout>
                      </>
                    ) : (
                      <></>
                    )}
                    {!medicines.length ? (
                      <Text size={16} lineHeight={18} center>
                        Aún no posee medicamentos seleccionados para esta
                        receta.
                      </Text>
                    ) : medicines.length >= 3 ? (
                      <Text size={16} lineHeight={18} center marginTop={32}>
                        No puede añadir más medicamentos a esta receta.
                      </Text>
                    ) : null}
                  </>
                </Card>
              </View>
              <View style={styles.cardItem}>
                <Card
                  header={{
                    icon: SOURCE_ICON.additionalInformation,
                    title: locales_es.otherConfigurations,
                  }}
                >
                  <View style={{ minHeight: 60, maxHeight: 60 }}>
                    <View>
                      <AccountItem
                        style={styles.switchExtended}
                        name={locales_es.extendedTreatment}
                        isToggle={true}
                        nameStyle={{ fontSize: 16, marginLeft: 0 }}
                        onPress={() => setExtendedTreatment(!extendedTreatment)}
                        // onValueChange={() => setExtendedTreatment(!extendedTreatment)}
                        switchValue={extendedTreatment}
                      />
                    </View>
                    <View
                      style={{
                        flex: 1,
                        marginTop: 16,
                      }}
                    >
                      <AccountItem
                        style={styles.switchExtended}
                        name={locales_es.hivPatient}
                        isToggle={true}
                        onPress={() => setHiv(!hiv)}
                        // onValueChange={() => setHiv(!hiv)}
                        nameStyle={{ fontSize: 16, marginLeft: 0 }}
                        switchValue={hiv}
                      />
                    </View>
                  </View>
                </Card>

                <Card
                  header={{
                    title: locales_es.diagnosis,
                    icon: SOURCE_ICON.condition,
                  }}
                >
                  <View style={{ height: 200 }}>
                    <DiagnosticAutocompleteItem
                      diagnosticIn={diagnostic}
                      diagnosticCodIn={diagnosticCod}
                      errorsIn={errors}
                      buttonIconFlag={true}
                      tagIn={"prescription-medicine"}
                      paddingRightFlagIn={true}
                      onPress={(item) => onPressSearchResult(item, -1)}
                      onChange={(text) => {
                        setDiagnostic(text);
                      }}

                      // widthLetter={'100%'}
                    />
                  </View>
                </Card>

                <Card
                  header={{
                    title: locales_es.indications,
                    icon: SOURCE_ICON.hospitalBed,
                  }}
                  style={{ zIndex: -1 }}
                >
                  <View style={{ height: 200 }}>
                    <TextInput
                      placeholderTextColor={theme.placeholder}
                      value={indications}
                      placeholder={locales_es.addPrescriptionIndications}
                      style={{
                        fontFamily: "Muli_400Regular",
                        fontSize: 15,
                        lineHeight: 18,
                        color: theme.text,
                        flex: 1,
                        borderWidth: 1,
                        borderColor: errors.includes("indications")
                          ? theme.lineErrorColor
                          : theme.innearColor,
                      }}
                      onChangeText={setIndications}
                      multiline={true}
                      editable={true}
                      onBlur={() => onBlur("indications")}
                    />
                  </View>
                </Card>

                <Card
                  header={{
                    title: "Texto adicional (Libre y opcional)",
                    icon: SOURCE_ICON.edit,
                  }}
                  style={{ zIndex: -1 }}
                >
                  <View style={{ height: 200 }}>
                    <TextInput
                      placeholderTextColor={theme.placeholder}
                      value={comments}
                      placeholder={locales_es.addPrescriptionComments}
                      style={{
                        fontFamily: "Muli_400Regular",
                        fontSize: 15,
                        lineHeight: 18,
                        color: theme.text,
                        flex: 1,
                        borderWidth: 1,
                        borderColor: theme.innearColor,
                      }}
                      onChangeText={setComments}
                      multiline={true}
                      editable={true}
                      onBlur={() => onBlur("comments")}
                    />
                  </View>
                </Card>
              </View>
            </View>

            {prescriptionError ? (
              <Modal visible={true} transparent={true} animationType={"slide"}>
                <ScrollView
                  style={{
                    flex: 1,
                    backgroundColor: "#fff",
                  }}
                  contentContainerStyle={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image source={iconWarning} style={styles.successImage} />
                  </View>
                  <View
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Text
                      center={true}
                      color={Colors.DarkJungleGreen}
                      style={[{ lineHeight: 28 }]}
                      size={24}
                    >
                      Hay un problema con su prescripción
                    </Text>
                    <Text
                      color={Colors.DarkJungleGreen}
                      style={{
                        textAlign: "center",
                        paddingHorizontal: 60,
                        lineHeight: 22,
                      }}
                      marginVertical={20}
                      size={16}
                    >
                      {prescriptionError}
                    </Text>
                    <ButtonLinear
                      style={{ paddingHorizontal: 12 }}
                      white
                      title="Revisar y corregir datos del afiliado"
                      onPress={correctPatientNoInsurance}
                    />
                    <ButtonBorder
                      style={{ paddingHorizontal: 12, marginTop: 12 }}
                      title="Realizar receta sin cobertura"
                      onPress={sendWithNoInsurance}
                    />
                    <Text
                      marginTop={20}
                      blueLight
                      type="H6"
                      color={Colors.BlueCrayola}
                      semibold
                      onPress={() => setPrescriptionError(false)}
                    >
                      {locales_es.close}
                    </Text>
                  </View>
                </ScrollView>
              </Modal>
            ) : null}
          </View>
        </View>
      )}
    </>
  );
};



export default AddPrescription;

const styles = StyleSheet.create({
  switch: {
    ...Theme.flexRowSpace,
    paddingTop: 12,
    paddingBottom: 12,
    left: 10,
    paddingRight: 20,
  },
  switchIconStyle: {
    tintColor: Colors.DodgerBlue,
    height: 20,
    maxHeight: 20,
  },
  switchExtended: {
    ...Theme.flexRowSpace,
    fontSize: 16,
  },
  lineThrough: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
  underline: {
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
  },
  buttonDate: {
    borderWidth: 0,
    marginVertical: 10,
  },
  sectionError: {
    borderWidth: 1,
    borderColor: "red",
    borderStyle: "solid",
    borderRadius: 16,
  },
  container: { flex: 1, minHeight: "100%", overflow: 'hidden' },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 24,
    gap: 24,
    flexWrap: "wrap",
  },
  cardItem: {
    flex: 1,
    gap: 24,
    minWidth: 300,
    maxWidth: 600,
    height: "fit-content",
  },
  cardAction: { tintColor: "#00c1c1", color: "#00c1c1" },
  prescriptionProfileWrapper: {
    overflow: "auto",
    marginLeft: -24,
    marginRight: -24,
    marginTop: -16,
    maxHeight: "100%",
  },
  headerWrapper: { flexDirection: "row", gap: 16, alignItems: "center" },
  user: {
    marginTop: 16,
    maxWidth: 1224,
  },
  userDataWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userName: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
  },
  prescriptionActionWrapper: {
    position: "fixed",
    right: 24,
    bottom: 16,
  },
  prescriptionAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
  },
  prescriptionButton: {
    borderRadius: 20,
    height: 40,
    width: 40,
    marginTop: 0,
    marginLeft: 8,
  },
  prescriptionIcon: { width: 20, height: 20, tintColor: "white" },
});


