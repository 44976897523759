import React, { useState, useCallback, useEffect, useRef } from "react";
import { Routes } from "../../../configs";
import { useHistory } from "react-router-dom";
import useModalWithKeyboard from "../../../hooks/useModalWithKeyboard";
import { uploadIdentificationImage } from "../../../modules/ApiHelpers";

import APIService from "../../../modules/apiService";
import AuthService from "../../../modules/authService";
import Helpers from "../../../modules/helpers";
import {
  EVENT_RELOAD_PRESCRIPTION_PROFILES,
  EVENT_SHOW_MODAL_INFO,
} from "../../../constants/Events";
import locales_es from "../../../locales/es";
import {
  validateEmptyArrays,
  validateEmptyFields,
} from "../../../utils/validation/forms";
import { EventRegister } from "../../../modules/EventRegister";
import { USER_STATUS_APPROVED } from "../../../models/constants";
import { showModalInfo } from "../../../modules/ViewHelpers";
import CountryService from "../../../modules/CountryService";
import ListItem from "../../../components/ListItem";
import InputApp from "../../../components/InputApp";
import TranslationService from "../../../locales";
import { useTheme } from "../../../configs/Theme";
import PrescriptionService from "../../../modules/PrescriptionService";
import { set } from "react-native-reanimated";

let nativeEventTestSearchSpecialties = "";

const useWorkProfilePage = () => {
  const history = useHistory();
  const params = history.location.state;

  const prescriptionService = new PrescriptionService();
  const prescriptionIdFromStorage = prescriptionService.get();

  let {prescriptionProfileId, stepTitle, firstProfile, hasSignup, stepNumber} =
    params ? params : null;
  prescriptionProfileId = (prescriptionProfileId) ? prescriptionProfileId : prescriptionProfileId;

  const api = new APIService();
  const auth = new AuthService();
  const translationService = new TranslationService();
  const helpers = new Helpers();
  const [hiddenText, setHiddenText] = useState(true);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [errors, setErrors] = useState([]);
  const { theme } = useTheme();
  const [translations, setTranslations] = useState(null);
  const [userData, setUserData] = useState({});
  // const [countryId, setCountryId] = useState('');
  // const [prescriptionProfileId, setPrescriptionProfileId] = useState(null);
  const [identificationImage, setIdentificationImage] = useState(null);
  const [searchSpecialties, setSearchSpecialties] = useState("");
  const [searchSpecialtiesResults, setSearchSpecialtiesResults] = useState<
    any[] | null | undefined
  >([]);
  const [specialties, setSpecialties] = useState([]);
  const [additionalFieldsOptions, setAdditionalFieldsOptions] = useState([]);
  const [clinicData, setClinicData] = useState(null);
  const [showStep, setShowStep] = useState(false);
  const [
    prescriptionProfileAdditionalFields,
    setPrescriptionProfileAdditionalFields,
  ] = useState([]);
  const [prescriptionProfileData, setPrescriptionProfileData] = useState({});

  const [medicalLicenseConfiguration, setMedicalLicenseConfiguration] =
    useState([]);
  const [medicalLicenseValue, setMedicalLicenseValue] = useState({});

  const [profession, setProfession] = useState("");

  const [configurations, setConfigurations] = useState(null);

  const imgLoadAndCrop = useRef();
  const [cropping, setCropping] = useState(false);

  const onSubmit = useCallback(() => {
    if (
      userData &&
      userData.status !== USER_STATUS_APPROVED &&
      !identificationImage
    ) {
      let title =
        translations && translations.confirmNoIdentificationImageRequired
          ? translations.confirmNoIdentificationImageRequired.title
          : locales_es.confirmNoIdentificationImageRequired.title;
      let subtitle =
        translations && translations.confirmNoIdentificationImageRequired
          ? translations.confirmNoIdentificationImageRequired.subtitle
          : locales_es.confirmNoIdentificationImageRequired.subtitle;
      showModalInfo(title, subtitle);
    } else {
      onSubmitConfirm();
    }
  }, [
    prescriptionProfileAdditionalFields,
    medicalLicenseConfiguration,
    medicalLicenseValue,
    specialties,
    profession,
    identificationImage,
  ]);

  const openCrop = () => {
    imgLoadAndCrop.current.selectImageFile();
  };

  const onSubmitConfirm = () => {
    const errors = validateEmptyFields({
      profession,
    });

    if (!validateEmptyArrays(specialties)) {
      errors.push("searchSpecialties");
    }

    medicalLicenseConfiguration.map((field: any) => {
      if (!medicalLicenseValue[field.code]) {
        errors.push(field.name);
      }
    });

    if (errors.length) {
      setErrors(errors);
      showModalInfo(locales_es.infoModal.title, locales_es.infoModal.checkData);
    } else {
      setLoading(true);
      const objData = {
        ...prescriptionProfileData,
        profession,
        specialties,
        additional_fields: {
          inputs: prescriptionProfileAdditionalFields,
        },
        medical_license: medicalLicenseValue,
        optional_fields: [], // NO SUPPORT FOR NOW
      };

      if (prescriptionProfileId) {
        api
          .putPrescriptionProfile(prescriptionProfileId, objData)
          .then(() => {
            sendEvent();
            setLoading(false);
            history.push(`/${Routes.OtherInformation}`, {
              userData,
              prescriptionProfileId,
              hasTwoSteps: false,
              stepTitle: (clinicData && !clinicData.paylink_enabled) ? "¡Último Paso!" : "Paso 2 de 3",
              stepNumber: 2,
              hasSignup: !!hasSignup,
              firstProfile
            });
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err)
            );
            setLoading(false);
          });
      } else {
        api
          .postPrescriptionProfile(objData)
          .then((res: any) => {
            sendEvent();
            setLoading(false);
            prescriptionService.set(res.data.id);
            history.push(`/${Routes.OtherInformation}`, {
              userData,
              prescriptionProfileId: res.data.id,
              hasTwoSteps: false,
              hasSignup: !!hasSignup,
              stepTitle: (clinicData && !clinicData.paylink_enabled) ? "¡Último Paso!" : "Paso 2 de 3",
              stepNumber: 2,
              firstProfile
            });
          })
          .catch((err: any) => {
            showModalInfo(
              locales_es.errorModal.title,
              helpers.getErrorMsg(err)
            );
            setLoading(false);
          });
      }
      // alert(JSON.stringify(prescriptionProfileAdditionalFields));
    }
  };

  const sendEvent = () => {
    EventRegister.emit(EVENT_RELOAD_PRESCRIPTION_PROFILES);
  };

  const onSearchSpecialtiesChange = (e) => {
    // e.persist(); // https://reactjs.org/docs/legacy-event-pooling.html
    nativeEventTestSearchSpecialties = e.nativeEvent.text;
    setSearchSpecialties(e.nativeEvent.text);
  };

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item) => item !== inputStr));
  };

  useEffect(() => {
    translationService.translate().then((translation) => {
      setTranslations(translation);
    });

    auth
      .getRemoteUserData()
      .then((res: any) => {
        if (res && res.data && res.data.user) {
          // setCountryId(res.data.user.country_id);
          setUserData(res.data.user);

          setIdentificationImage(
            res.data.user.full_identification_image
              ? {
                  uri: res.data.user.full_identification_image,
                }
              : false
          );

          api
            .getCountries()
            .then(async (res3: any) => {
              setPrescriptionProfileAdditionalFields(
                res3.data.filter(
                  (country: any) => country.id === res.data.user.country_id
                )[0].prescription_profile_additional_fields.inputs
              );

              const additionalFieldsOptionsRes = await api.getConfigurations();
              setMedicalLicenseConfiguration(
                additionalFieldsOptionsRes.configurations
              );

              if (prescriptionProfileId) {
                api
                  .getPrescriptionProfile(prescriptionProfileId)
                  .then((res2: any) => {
                    setProfession(res2.data.profession);
                    setSpecialties(
                      res2.data.specialties ? res2.data.specialties : []
                    );
                    setPrescriptionProfileData(res2.data);
                    setMedicalLicenseValue(res2.data.medical_licenses || {});
                    setPrescriptionProfileAdditionalFields(
                      res2.data.additional_fields.inputs
                    );
                    if (res2.data.clinic) {
                      setClinicData(res2.data.clinic);
                    }
                    setShowStep(true);
                  });
              }
            })
            .catch((err3: any) => {
              showModalInfo(
                locales_es.infoModal.title,
                helpers.getErrorMsg(err3)
              );
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [load]);

  useEffect(() => {
    if (searchSpecialties && searchSpecialties.length > 2) {
      setSearchSpecialtiesResults(null);
      const objData = {
        q: searchSpecialties,
      };
      setTimeout(() => {
        if (
          searchSpecialties.length === nativeEventTestSearchSpecialties.length
        ) {
          api
            .searchSpecialties(objData)
            .then((res) => {
              console.log(res);
              setSearchSpecialtiesResults(res.data);
            })
            .catch((err) => {
              showModalInfo(
                locales_es.infoModal.title,
                helpers.getErrorMsg(err)
              );
            });
        } else {
          setSearchSpecialtiesResults([]);
        }
      }, 400); // 800
    }
  }, [searchSpecialties]);

  useEffect(() => {
    // Check if practices are enabled (enabled_certificates)
    const countryService = new CountryService();
    countryService
      .getData()
      .then((res: any) => {
        if (res && res.code === "ar") {
          setHiddenText(false); // Hay un texto que por ahora se muestra solo en ARG
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const onPressSearchResult = (name) => {
    const duplicated = specialties.filter((item) => item === name);
    if (duplicated.length) {
      showModalInfo(
        locales_es.infoModal.title,
        "Ya has agregado esta especialidad"
      );
      return;
    }

    if (name) {
      setSpecialties([...specialties, name]);
      setSearchSpecialtiesResults([]);
      setSearchSpecialties("");
    }
  };

  const onPressSpecialtyX = (index) => {
    const items = JSON.parse(JSON.stringify(specialties));
    items.splice(index, 1);
    setSpecialties(items);
  };

  const renderListItem = (item) => {
    return (
      <ListItem
        key={item}
        item={{
          id: new Date().getTime(),
          name: item,
        }}
        onPress={() => onPressSearchResult(item)}
      />
    );
  };

  const onChangeInput = (text, additionalField) => {
    const newFilters = JSON.parse(
      JSON.stringify(prescriptionProfileAdditionalFields)
    );
    if (newFilters && newFilters.length) {
      const newFilter = medicalLicenseConfiguration.filter(
        (field) => field.code === additionalField.code
      )[0];

      newFilters.filter(
        (field) =>
          field.name ===
          `professional_registration_${
            additionalField.code !== "identification"
              ? additionalField.code
              : "number"
          }`
      )[0].value =
        newFilter.input_type !== "text"
          ? newFilter.code === "type"
            ? additionalField.text
            : additionalField.name
          : text;

      medicalLicenseValue[additionalField.code] =
        newFilter.input_type !== "text" ? additionalField.id : text;
      if (additionalField.id === 1) {
        medicalLicenseValue.jurisdiction = 1;
      }
      setMedicalLicenseValue(medicalLicenseValue);

      setPrescriptionProfileAdditionalFields(newFilters);
    }
  };

  const getTextFromId = (options: any, id: any) => {
    if (!options) return "";
    for (const option of options) {
      if (option.id === id) {
        return option.text || option.name;
      }
    }
    return "";
  };

  const handleOnPressInputSelect = (additionalField: any) => {
    let additionalFieldOptions = additionalField.options;
    if (additionalField.id === 4 && medicalLicenseValue.type === 2) {
      additionalFieldOptions = additionalField.options.filter(
        (option) => option.id !== 1 && option.id !== 3
      );
    }

    setAdditionalFieldsOptions(additionalFieldOptions);
  };

  const renderAdditionalField = (additionalField: any) => {
    if (additionalField.input_type === "select") {
      let value = getTextFromId(
        additionalField.options,
        medicalLicenseValue[additionalField.code]
      );
      if (additionalField.id === 4 && medicalLicenseValue.type === 2) {
        value = getTextFromId(
          additionalField.options.filter(
            (option) => option.id !== 1 && option.id !== 3
          ),
          medicalLicenseValue[additionalField.code]
        );
      }

      return (
        <>
          {medicalLicenseValue.type === 1 && additionalField.id === 4 ? null : (
            <InputApp
              key={additionalField.id}
              title={additionalField.label}
              marginTop={24}
              value={value}
              editable={false}
              onPress={() => {
                handleOnPressInputSelect(additionalField);
                openAdditionalFieldMenuOption();
              }}
              borderColor={
                errors.includes(additionalField.code)
                  ? theme.lineErrorColor
                  : theme.innearColor
              }
              onBlur={() => onBlur(additionalField.name)}
              showRequired={true}
            />
          )}
        </>
      );
    }
    if (additionalField.input_type === "text") {
      return (
        <InputApp
          key={additionalField.id}
          placeholder={additionalField.label}
          title={additionalField.label}
          value={medicalLicenseValue[additionalField.code]}
          autoCapitalize="words"
          marginTop={24}
          borderColor={
            errors.includes(additionalField.code)
              ? theme.lineErrorColor
              : theme.innearColor
          }
          onChangeText={(text) => {
            onChangeInput(text, additionalField);
          }}
          onBlur={() => onBlur(additionalField.name)}
          showRequired={true}
        />
      );
    }
    return null;
  };

  const {
    visible: visibleAdditionalFieldMenuOption,
    open: openAdditionalFieldMenuOption,
    close: closeAdditionalFieldMenuOption,
  } = useModalWithKeyboard(false);

  const uploadImage = (image) => {
    setCropping(false);
    setLoading(true);
    setLoadingText(locales_es.uploadingImage);
    uploadIdentificationImage(image)
      .then(() => {
        setLoading(false);
        setLoadingText("");
        setIdentificationImage({
          uri: image,
        });
      })
      .catch(() => {
        setLoading(false);
        setLoadingText("");
      });
  };

  return {
    stepTitle,
    hiddenText,
    loading,
    loadingText,
    searchSpecialties,
    searchSpecialtiesResults,
    additionalFieldsOptions,
    imgLoadAndCrop,
    cropping,
    setCropping,
    onSubmit,
    onBlur,
    openCrop,
    onSearchSpecialtiesChange,
    onPressSearchResult,
    onPressSpecialtyX,
    renderListItem,
    renderAdditionalField,
    onChangeInput,
    visibleAdditionalFieldMenuOption,
    closeAdditionalFieldMenuOption,
    uploadImage,
    theme,
    profession,
    setProfession,
    errors,
    specialties,
    translations,
    prescriptionProfileAdditionalFields,
    userData,
    identificationImage,
    stepNumber,
    medicalLicenseConfiguration,
    clinicData
  };
};

export default useWorkProfilePage;
