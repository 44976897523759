import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { EVENT_RELOAD_PATIENTS, EVENT_SHOW_MODAL_INFO } from "../../../constants/Events";
import {api, auth, helpers} from '../../../import';
import { EventRegister } from "../../../modules/EventRegister";
import { showModalInfo } from "../../../modules/ViewHelpers";
import {Colors, Routes} from '../../../configs';
import useModalWithKeyboard from '../../../hooks/useModalWithKeyboard';
import locales_es from '../../../locales/es';
import { INSURANCE_PERMISSION, MEDICINES_PERMISSION, USER_STATUS_APPROVED } from "../../../models/constants";

const usePatientProfilePage = () => {
  const history = useHistory();
  const params = history.location.state;
  const patientId = params && params.patientId ? params.patientId : null;
  const historyBackSteps = params && params.historyBackSteps ? params.historyBackSteps : null;
  const readOnly = params && params.readOnly ? params.readOnly : null;

  const [load, setLoad] = useState(false);
  const [userData, setUserData] = useState(null);
  const [medicalInsurances, setMedicalInsurances] = useState<any>([]);
  const [images, setImages] = useState(null);
  const [insurancePermission, setInsurancePermission] = useState(false);
  const [medicinePermission, setMedicinePermission] = useState(false);
  const [visibleModalChange, setVisibleModalChange] = useState(false);
  const [userStatusEnabled, setUserStatusEnabled] = useState(false);

  const closeChange = useCallback(() => {
    setVisibleModalChange(false);
  }, []);

  const [visibleModalDecline, setVisibleModalDecline] = useState(false);
  const closeDecline = useCallback(() => {
    setVisibleModalDecline(false);
  }, []);

  const {visible, open, close, translateY} = useModalWithKeyboard(false); //show modal


  const goBack = () => {

      history.push(`/${Routes.MyPatients}`);
    }  

  const onCreatePrescription = () => {
    history.push(`/${Routes.AddPrescription}`, {
      patientId,
    });
  };

  const onCreateCertificate = () => {
    history.push(`/${Routes.AddCertificate}`, {
      patientId,
    });
  };

  const onUserEdit = () => {
    history.push(`/${Routes.AddPatient}`, {
      patientId,
      screenTitle: locales_es.editPatient,
    });
  };

  const onUserDelete = () => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: locales_es.deleteUser.title,
      message: locales_es.deleteUser.subtitle,
      actions: [
        {
          text: locales_es.deleteUser.primaryAction,
          onPress: () => {
            api
              .deletePatient(patientId)
              .then((res: any) => {
                EventRegister.emit(EVENT_RELOAD_PATIENTS, {});
                showModalInfo(locales_es.successModal.title, res.message);
                history.goBack();
              })
              .catch((err: any) => {
                showModalInfo(
                  locales_es.errorModal.title,
                  helpers.getErrorMsg(err),
                );
              });
          },
          style: 'cancel',
          colors: [Colors.WhiteGray, Colors.WhiteGray],
        },
        {
          text: locales_es.cancel,
          onPress: () => console.log('Cancel Pressed'),
          white: true,
        },
      ],
    });
  };

  const onRemoveAttachment = index => {
    if (!images) return;
    const itemToRemove = images[index];
    api
      .deletePatientImage(patientId, itemToRemove.id)
      .then((res: any) => {
        showModalInfo(locales_es.successModal.title, res.message);
        setLoad(!load);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
  };

  const loadView = () => {
    auth.getLocalUserData().then(
      (data: any) => {
        setUserStatusEnabled(data.user.status === USER_STATUS_APPROVED);
      }
    )
    auth.getPermissions().then((permissions: any) => {
      api
      .getPatientMedicalInsurances(patientId)
      .then((res: any) => {

        if (permissions && !permissions.some((perm: any) => perm.tag === INSURANCE_PERMISSION)) {
          const newItem = [
            {
              id: 0,
              medical_insurance_id: 1,
              medical_insurance_plan: '',
              medical_insurance_number: '',
              medical_insurance_name: 'Sin cobertura',
            },
          ];
          setMedicalInsurances(newItem);

        }
        else {
          let data = res.data.map((item: any) => { 
            return {
              medical_insurance_id: item.medical_insurance_id,
              medical_insurance_name: item.medical_insurance.name,
              medical_insurance_plan: item.medical_insurance_plan,
              medical_insurance_number: item.medical_insurance_number,
              medical_insurance_dni_titular: item.medical_insurance_dni_titular
            }
          })
          data = data.sort((a, b) => {
            if (a.medical_insurance_id === 1) return 1;
            if (b.medical_insurance_id === 1) return -1;
            return 0;
          });
        
          setMedicalInsurances(data);
        }

      })
      .catch((err: any) => {
        helpers.processError(err);
      });

      
      auth.getLocalUserData().then((data: any) => {
        setInsurancePermission(permissions.some(p => p.tag === INSURANCE_PERMISSION));
        setMedicinePermission(permissions.some(p => p.tag === MEDICINES_PERMISSION) && data.user.status === USER_STATUS_APPROVED);
      })
    });
    api
      .getPatient(patientId)
      .then((res: any) => {
        setUserData(res.data);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });

    api
      .getPatientImages(patientId)
      .then((res: any) => {
        setImages(res.data);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });

   
  };

  useEffect(() => {
    loadView();
    // getPatientMedicalInsurances();
  }, [load]);

  useEffect(() => {
    const listener = EventRegister.addEventListener(
      EVENT_RELOAD_PATIENTS,
      data => {
        loadView();
      },
    );

    return function cleanup() {
      EventRegister.removeEventListener(listener);
    };
  });

  return {
    goBack,
    userData,
    patientId,
    historyBackSteps,
    readOnly,
    images,
    visibleModalChange,
    closeChange,
    visible, close, translateY,
    onCreatePrescription,
    onCreateCertificate,
    onUserEdit,
    onUserDelete,
    onRemoveAttachment,
    medicalInsurances, 
    setMedicalInsurances,
    insurancePermission,
    medicinePermission,
    userStatusEnabled
  };
};

export default usePatientProfilePage;
