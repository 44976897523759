import React from "react";
import { View, StyleSheet, ActivityIndicator, Image, TouchableOpacity, } from "react-native";
import { Colors, Routes } from "../../../configs";
import {
  getStatusBarHeight,
} from "react-native-iphone-x-helper";
import locales_es from "../../../locales/es";
import { CommonStyles } from "../../../styles/CommonStyles";
import {  width } from "../../../configs/Const";
import HeaderButton from "../../../components/HeaderButton";
import usePatientCertificatesPage from "../shared/usePatientCertificatesPage";
import { dateTimeService } from "../../../import";
import { SOURCE_ICON } from "../../../images";
import TouchableOpacityHOC from "../../../components/TouchableOpacityHOC";
import Text from "../../../components/Text";
import avatarProfileIcon from "../../../images/avatar/ic_account_normal1.png";
import usePatientProfilePage from "../../PatientProfile/shared/usePatientProfilePage";
import { useHistory } from "react-router-dom";

interface PatientCertificatesProps {}

const PatientCertificates = (props: PatientCertificatesProps) => {
  const history = useHistory();
  const {certificates, refreshing, onLoadMore,total } = usePatientCertificatesPage()
  const { userData } = usePatientProfilePage();

  const seePrescription = ({
    full_file_url,
    id,
    type,
    patient,
    prescriptionId,
  }) => {
    const pdfSource = { uri: full_file_url };
    history.push(`/${Routes.DocumentVisor}`, {
      documents: [
        {
          pdfSource,
          prescriptionId: prescriptionId || id,
        },
      ],
      selectionType: type,
      emails: patient && patient.email ? [{ value: patient.email }] : null,
      hideToast: Boolean(prescriptionId || id),
      overlay: true
    });
  };

  const renderSpinner = () => {
    return (
      <View style={{ flex: 1, width: width }}>
        <ActivityIndicator style={CommonStyles.activityIndicator} />
      </View>
    );
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.headerWrapper}>
            <HeaderButton />
            <Text size={38} lineHeight={36} bold>
              {locales_es.patientDescription_page.title}
            </Text>
          </View>
          {userData && (
          <View style={styles.userDataWrapper}>
            <View>
              <Image
                source={
                  userData?.full_profile_image
                    ? { uri: userData.full_profile_image }
                    : avatarProfileIcon
                }
                style={{ width: 50, height: 50, borderRadius: 25 }}
              />
            </View>
            <View>
              <Text size={20} lineHeight={24} semibold marginRight={32}>
                {userData.name} {userData.lastname}
              </Text>
              {userData.gender && userData.gender.name && (
                <Text size={18} lineHeight={22} bold marginRight={32}>
                  {userData.gender.name}
                </Text>
              )}
            </View>
          </View>)}
          <View style={{ overflow: "auto" }}>
            <View style={styles.tableRowHeader}>
              <View
                style={[styles.columnHeader, { minWidth: 200, width: 200 }]}
              >
                <Text color={"#189394"} bold size={18}>
                  {locales_es.patientCertificates_page.date}
                </Text>
              </View>
              <View style={[styles.columnHeader, { flex: 1 }]}>
                <Text color={"#189394"} bold size={18}>
                  {locales_es.patientCertificates_page.text}
                </Text>
              </View>
              <View style={[styles.columnHeader]}>
                <Text color={"#189394"} bold size={18}>
                  {locales_es.patientCertificates_page.comments}
                </Text>
              </View>
              <View
                style={[styles.columnHeader, { minWidth: 150, width: 150 }]}
              >
                <Text color={"#189394"} bold size={18} center>
                  {locales_es.patientDescription_page.actions}
                </Text>
              </View>
            </View>

            {certificates === null ? (
              renderSpinner()
            ) : certificates.length ? (
              <View style={styles.tableWrapper}>
                {certificates.map((prescription, index) => (
                  <TouchableOpacityHOC key={prescription.id} onPress={() => {
                    seePrescription(prescription);
                  }}>
                    <View style={styles.tableRow}>
                      <View
                        style={[styles.column, { minWidth: 200, width: 200 }]}
                      >
                        <Text size={14}>
                          {dateTimeService.reverseAPIStringDate(
                            prescription.date
                          )}
                        </Text>
                      </View>
                      <View style={[styles.column, { flex: 1 }]}>
                        <Text size={14}>
                        {prescription.text || "No Especificado"}
                        </Text>
                      </View>
                      <View style={[styles.column]}>
                        <Text size={14}>
                          {prescription.indications || "No Especificado"}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.column,
                          {
                            minWidth: 150,
                            width: 150,
                            justifyContent: "center",
                          },
                        ]}
                      >
                        <TouchableOpacity
                          style={styles.cardItemAction}
                          onPress={() => {
                            seePrescription(prescription);
                          }}
                        >
                          <Image
                            source={SOURCE_ICON.eyeOn}
                            style={styles.cardItemIcon}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  </TouchableOpacityHOC>
                ))}
              </View>
            ) : (
              <Text
                size={14}
                lineHeight={20}
                marginTop={30}
                center
                style={{ marginHorizontal: 24 }}
              >
                {locales_es.myPatients_page.not_found}
              </Text>
            )}
          </View>
        </View>
      </View>
    </>
  );
};

export default PatientCertificates;

const styles = StyleSheet.create({
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: {
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
    marginBottom: 16,
  },
  tableRowHeader: { display: "flex", flexFlow: "row" },
  columnHeader: {
    minWidth: 150,
    width: "calc(100% / 6)",
    wordBreak: "break-word",
    padding: 16,
    paddingLeft: 0,
  },
  tableRow: { display: "flex", flexFlow: "row" },
  column: {
    minWidth: 150,
    wordBreak: "break-word",
    width: "calc(100% / 6)",
    padding: 16,
    paddingLeft: 0,
    display: "flex",
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
  },
  tableWrapper: { maxHeight: "calc(100vh - 300px)" },
  cardItemAction: { alignSelf: "center" },
  cardItemIcon: {
    width: 25,
    height: 25,
    tintColor: Colors.DodgerBlue,
  },
  userDataWrapper: {
    marginTop: 16,
    marginBottom: 16,
    paddingBottom: 24,
    borderBottomColor: "#e6e5e5",
    borderBottomWidth: 1,
    flexDirection: "row",
    gap: 16,
    alignItems: "center",
    flexWrap: "wrap",
  },
});
